import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import flattenObject from '../../../Utils/flattenObject';
import Nav from '../../Global/Nav';
import ListingTable from '../../Data/ListingMaster';

function AdminGamification() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      axios
        .get("/fb/get_badges")
        .then((res) => {
          const badgeData = res.data;
          setLoading(false);
  
          console.log(badgeData);
  
          // Set data
          setData(badgeData.map((item) => flattenObject(item)));
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
    const columns = useMemo(
      () => [
        {
          Header: "Title",
          accessor: "title", // accessor is the "key" in the data
        },
        {
          Header: "Category",
          accessor: "category",
        },
        {
          Header: "Address",
          accessor: "address",
        },
        {
          Header: "Map",
          accessor: "map",
        },
      ], []);
  
    console.log(data);
    return (
      <div>
        <Nav />
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <ListingTable columns={columns} data={data} />
        )}
      </div>
    );
  
}

export default AdminGamification
