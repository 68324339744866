import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

function HomeFeatureRow({ children, reverse, windowWidth, imgSrc }) {
  const [ref, inView] = useInView({
    triggerOnce: false, // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },
    delay: 400,
    speed: 0.1,
  });

  const slideFromLeft = useSpring({
    from: { transform: 'translate3d(-100px,0,0)', opacity: 0},
    to: { transform: inView ? 'translate3d(0,0,0)' : 'translate3d(-100px,0,0)', opacity: 1, },
    speed: 0.1,
  });

  const slideFromRight = useSpring({
    from: { transform: 'translate3d(100px,0,0)', opacity: 0},
    to: { transform: inView ? 'translate3d(0,0,0)' : 'translate3d(100px,0,0)', opacity: inView? 1 :0,},
    speed: 0.1,
  });

  const slideFromBottom = useSpring({
    from: { transform: 'translate3d(0,500px,0)', opacity: 0},
    to: { transform: inView ? 'translate3d(0,0,0)' : 'translate3d(0,500px,0)', opacity: inView ? 1 :0,},
    speed: 0.1,
  });

  return (
    <animated.div ref={ref} className="homeFeatureRow flex flex-col lg:flex-row w-full mx-auto py-5 md:py-20 my-auto justify-between h-full" style={{maxWidth:1600, flexDirection: windowWidth > 770 ? (reverse ? "row-reverse" : "row") : "flex-column"}}>
      <animated.div className={["w-full md:w-2/3 transition-ease " + (reverse ? "ml-0 sm:ml-10 md:ml-32":"mr-10 md:mr-32")]} style={!reverse ? slideFromLeft : slideFromRight}>
        {children}
      </animated.div>
      <animated.div className="min-h-10 w-full md:w-1/3 opacity-0" style={slideFromBottom}>
      <img src={imgSrc} alt="feature" className="w-full h-full max-w-sm" />
      </animated.div>
    </animated.div>
  );
}

export default HomeFeatureRow;