import React, { createContext, useContext, useState } from 'react';

const CsrfTokenContext = createContext();

export const Csrf = ({ csrfToken, children }) => {
  const [contextCsrfToken, setContextCsrfToken] = useState(csrfToken);
  const apiKey = process.env.REACT_APP_BRAVEN_API_KEY; // Replace REACT_APP_BRAVEN_API_KEY with your actual environment variable
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  return (
    <CsrfTokenContext.Provider value={{ csrfToken: contextCsrfToken, setCsrfToken: setContextCsrfToken, apiKey:apiKey, recaptchaKey:recaptchaKey}}>
      {children}
    </CsrfTokenContext.Provider>
  );
};

export const useCsrfToken = () => {
  return useContext(CsrfTokenContext);
};