import React, { useMemo, useState } from "react";
import { FixedSizeList as List } from "react-window";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import { useCsrfToken } from "../Auth/Csrf";
import { set } from "firebase/database";
import Switch from "@mui/material/Switch";

function ListingTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const { csrfToken } = useCsrfToken();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [publish, setPublish] = useState(false);

  const config = {
    headers: {
      "X-CSRFToken": csrfToken,
    },
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handlePublish = async (row) => {
    // Calculate the new publish state
    const newPublishState = !row.original.published;

    console.log("new publish state", newPublishState);

    // Update the row's publish state immediately
    row.original.published = newPublishState;

    console.log("row doc id", row.original.doc_id);

    axios
      .post(
        "/fb/update_listing/",
        { id: row.original.doc_id, data: { published: newPublishState } },
        config
      )
      .then((res) => {
        console.log(res);
        setPublish(!publish);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleDelete = async (row) => {
  //     if (window.confirm('Are you sure you want to delete this record?')) {
  //         await db.collection('placeListing').doc(row.id).delete();
  //     }
  // }

  return (
    <table {...getTableProps()} className="w-full h-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
            <th>Edit</th>
            <th>Published</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="w-full">
        {/* <List
                    height={500}
                    itemCount={rows.length}
                    itemSize={35}
                    width="auto"
                    className='w-full'
                > */}
        {/* {({ index, style }) =>  */}
        {rows.map((row, index) => {
          // console.log(row)
          prepareRow(row);
          return (
            <tr>
              {row.cells.map((cell) => {
                // console.log(cell)

                if (cell.column.Header === "Map") {
                  return (
                    <td>
                      <a href={cell.value} target="_blank">
                        View on Map
                      </a>
                    </td>
                  );
                } else if (cell.column.Header === "Added") {
                  const date = new Date(cell.value);
                  return <td>{date.toLocaleString()}</td>;
                } else {
                  return <td>{cell.value}</td>;
                }
              })}
              <td>
                <button onClick={() => handleEdit(row)}>Edit</button>
              </td>
              {/* {console.log("this is the id",row.values.published)} */}
              <td>
                {/* <input 
                                    type="checkbox" 
                                    checked={row.original.published} 
                                    onChange={() => handlePublish(row)}
                                /> */}
                <Switch
                  checked={row.original.published}
                  onChange={() => handlePublish(row)}
                />

                {/* <button onClick={() => handleDelete(row)}>Delete</button> */}
              </td>
            </tr>
          );
        })}
        {/* </List> */}
      </tbody>
    </table>
  );
}

export default ListingTable;
