import React from 'react'

function Footer() {
    const year = new Date().getFullYear();
  return (
    <footer className='w-full min-h-20 flex flex-col py-3'>
      <div className='m-auto'><p>PawPal &copy; {year}</p></div>

      <a href="#" className="termly-display-preferences m-auto my-2">Consent Preferences</a>
    </footer>
  )
}

export default Footer


