import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useCsrfToken } from "../Auth/Csrf";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import { useSpring, animated } from "@react-spring/web";
import ReactGA from "react-ga4";
import { Checkbox } from "@mui/material";

function WaitlistForm({ formActive, formActiveFromParent }) {
  const { csrfToken, apiKey } = useCsrfToken();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const timerRef = useRef(null);
  const emailInputRef = useRef(null);
  const termsCheckboxRef = useRef(null);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const slideFromBottom = useSpring({
    from: { transform: "translate3d(0,200px,0)", opacity: 0 },
    to: { transform: "translate3d(0,0,0)", opacity: 1 },
    speed: 0.1,
  });

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
    speed: 1000,
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const aliasRegex = /.+\+.+@.+/;
    const isAlias = aliasRegex.test(email);
    const validity = {
      email: emailRegex.test(email),
      alias: process.env.NODE_ENV === "production" ? !isAlias : true,
    };

    return validity;
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      if (email != "") {
        const emailValid = validateEmail(email);

        if (!emailValid.alias) {
          setError("Invalid email. Aliases are not allowed.");
        } else if (!emailValid.email) {
          setError("Invalid email. Please enter a valid email address.");
        } else {
          setError(null);
        }
      } else {
        setError(null);
      }
    }, 600);
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email != "") {
      const emailValid = validateEmail(email);

      if (!emailValid.alias) {
        setError("Invalid email. Aliases are not allowed.");
        return;
      }

      if (!emailValid.email) {
        setError("Invalid email. Please enter a valid email address.");
        return;
      }

      if (!termsAgreed) {
        setError("Please agree to the terms and conditions to continue");
        return;
      }
    } else {
      setError("Need an email address to continue");
      return;
    }

    if (!executeRecaptcha) {
      setError("reCAPTCHA not ready");
      return;
    }

    const token = await executeRecaptcha("waitlist_form");

    axios
      .post(
        "https://api.brevo.com/v3/contacts",
        { email: email, listIds: [2] },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": csrfToken,
            "api-key": apiKey,
          },
        }
      )
      .then((response) => {
        setEmail("");
        setError(null);
        setTermsAgreed(false);
        setSubmitted(true);
      })
      .catch((error) => {
        // Clear the email field and error message
        console.log(error.response);
        const errorCode = error.response.data.code;
        if (errorCode === "duplicate_parameter") {
          setError("Your email is already on the list! ");
        } else {
          setError("An error occurred. Please try again later.");
        }
      });
  };

  const handleShowForm = () => {
    setShowForm(true);
    formActive(true);
    // Send a custom event

    console.log("Sending event", ReactGA);

    ReactGA.event({
      category: "btnClick",
      action: "Clicked join waitlist in hero section",
      label: "Join Waitlist btn click", // optional
    });
  };

  useEffect(() => {
    if (formActiveFromParent && !showForm) {
      handleShowForm();
    }
  }, [formActiveFromParent]);

  useEffect(() => {
    if (showForm && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showForm]);

  useEffect(() => {
    if (termsCheckboxRef?.current?.children[0].checked) {
      console.log("Terms agreed");
      setTermsAgreed(true);
    } else {
      console.log("Terms not agreed");
      setTermsAgreed(false);
    }
  }, [termsCheckboxRef?.current]);

  return (
    <div>
      {!showForm ? (
        <button
          className="transButtonOnTeal large px-5 py-2 my-5"
          onClick={handleShowForm}
        >
          Join Waitlist
        </button>
      ) : !submitted ? (
        <animated.div className="opacity-0" style={fade}>
          <form onSubmit={handleSubmit} className="flex flex-col md:max-w-sm">
            <input
              ref={emailInputRef}
              className="emailInput onTealBg my-1 mt-2 p-3"
              placeholder="Your email address"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <div className="flex flex-row mt-2 mb-0">
              <Checkbox
                ref={termsCheckboxRef}
                name="gdpr"
                id="gdpr"
                onChange={(event) => {
                  setTermsAgreed(event.target.checked);

                }}
                required
                className="my-1 w-max"
                sx={{
                  color: "#fff",
                  "&.Mui-checked": {
                    color: "var(--white)",
                    border: "none",
                    backgroundColor: "var(--primary)",
                  },
                  "&.MuiIconButton-root": {
                    borderColor: "#000",
                  },
                }}
              />
              <label htmlFor="gdpr" className="text-white my-auto text-xs">
                I have read and understood the{" "}
                <a href="/privacy" target="_blank" className="underline">
                  Privacy Policy
                </a>{" "}
                and am happy to be contacted about progress updates
              </label>
            </div>
            {error && <p className="formError px-3">{error}</p>}
            <button
              type="submit"
              className={
                "transButtonOnTeal submit large px-5 py-2 my-3" +
                (email != "" ? " filledButton" : " disabled")
              }
            >
              Submit
            </button>
          </form>
        </animated.div>
      ) : (
        <animated.div
          id="submittedFormContainer"
          className="flex flex-row my-5"
          style={slideFromBottom}
        >
          {/* Add double tick icon */}
          <span className="w-max mr-3">
            <DoneAllRoundedIcon />
          </span>
          <p>
            Great! You're on the list. Look out for our update emails from
            waitlist@getpawpal.co.uk
          </p>
        </animated.div>
      )}
    </div>
  );
}

export default WaitlistForm;
