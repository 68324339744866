import React, { useState } from "react";
import { Collapse } from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ReactGA from "react-ga4";

function Faq({ title, children }) {
  const [open, setOpen] = useState(false);

  const handleFaqOpen = () => {
    setOpen(!open);
    ReactGA.event({
      category: "btnClick",
      action: "Clicked FAQ - " + title,
      label: "Join Waitlist btn click", // optional
    })
  };

  return (
    <div
      onClick={handleFaqOpen}
      className="faq w-full md:w-2/3 mx-auto py-5"
    >
      <div className="flex justify-between">
        <h3 className="text-left">{title}</h3>
        <div
          className="transition-ease"
          style={{
            transition: "0.3s ease-in-out",
            transform: open ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <ExpandLessRoundedIcon fontSize="large" />
        </div>
      </div>
      <Collapse in={open} className="text-left">
      <p className="pt-3">
      {children}</p></Collapse>
    </div>
  );
}

export default Faq;
