import React, { useEffect, useState } from "react";
import Nav from "../Global/Nav";
import HomeFeatureRow from "../Data/HomeFeatureRow";
import { animated, useSpring } from "@react-spring/web";
import WaitlistForm from "../Global/WaitlistForm";
import Footer from "../Global/Footer";
import Faq from "../Data/Faq";

function HomeScreen() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formActive, setFormActive] = useState(false);
  const [openEmailInput, setOpenEmailInput] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileBreak = 200;
  const tabletBreak = 500;
  const desktopBreak = 700;
  const largeBreak = 1500;

  const heroImg1 = useSpring({
    from: { opacity: 0, transform: "rotate(0deg) translateY(100px)" },
    to: {
      opacity: 1,
      transform: "rotate(-5deg) translateY(0px)",
      left:
        windowWidth > largeBreak
          ? -160
          : windowWidth > 800
          ? -40
          : windowWidth > tabletBreak && windowWidth < largeBreak
          ? "auto"
          : -20,
      right:
        windowWidth > tabletBreak && windowWidth < largeBreak ? 20 : "auto",
      bottom:
        windowWidth > tabletBreak && windowWidth < largeBreak ? -120 : "auto",
    },
    speed: 0.1,
  });

  const heroImg2 = useSpring({
    from: { opacity: 0, transform: "rotate(0deg) translateY(100px)" },
    to: {
      opacity: 1,
      transform: "rotate(5deg) translateY(0px)",
      right:
        windowWidth > largeBreak
          ? -10
          : windowWidth <= largeBreak && windowWidth > desktopBreak
          ? -10
          : windowWidth <= 400
          ? -20
          : 0,
      bottom:
        windowWidth > tabletBreak && windowWidth < largeBreak
          ? -120
          : windowWidth <= largeBreak && windowWidth > desktopBreak
          ? 0
          : windowWidth >= tabletBreak
          ? 120
          : windowWidth <= 400
          ? 0
          : 30,
    },
    speed: 0.1,
    delay: 400,
  });

  if (!windowWidth) return null;

  return (
    <div className="flex flex-col flex-1">
      <div className="relative flex 2xl:px-32">
        <Nav
          windowWidth={windowWidth}
          emailInputOpen={(e) => setOpenEmailInput(e)}
        />
      </div>
      <div className="primaryBackground h-max md:h-svh flex 2xl:px-32">
        {/* HERO ROW CONTENT START */}
        <div
          id="hero-row"
          className="flex flex-col md:flex-row w-full px-10 lg:px-20 my-auto justify-between h-full pt-20 md:pt-40"
        >
          <div
            id="hero-left"
            className="w-full pt-10 md:pt-0 md:w-1/2 lg:w-2/3 2xl:w-1/2 max-w-3xl relative"
            style={{ zIndex: 100 }}
          >
            <div
              className={
                "relative transition-ease md:pt-3 lg:pt-10" +
                (formActive ? " lg:pt-10" : "")
              }
              style={{ zIndex: 100 }}
            >
              <h1 className="text-white" style={{lineHeight:"45px"}}>
                Find & review Dog Friendly locations near you
              </h1>
              <p className="text-white py-5">
                <span className="italic font-bold">“Yes, we allow dogs”</span>{" "}
                .... **proceeds to climb through a crowd of people...
                <br></br>
                <br></br>
                Find and review truly dog friendly locations near you, or across
                the UK. Those establishments that are more excited to see your
                pup than you, where their experience is as important as yours.{" "}
                <br></br>
                <br></br>
                Join the PawPal waitlist, and be the first to find hidden gems
                nearby.
              </p>
              <WaitlistForm
                formActive={(e) => setFormActive(e)}
                formActiveFromParent={openEmailInput}
              />
            </div>
            <div
              className="hidden lg:flex z-50 absolute bottom-0"
              style={{
                zIndex: 10,
                width:
                  windowWidth >= largeBreak
                    ? 400
                    : windowWidth >= 900
                    ? 300
                    : windowWidth >= desktopBreak
                    ? 300
                    : windowWidth >= tabletBreak
                    ? 220
                    : 200,
              }}
            >
              <img
                style={{
                  transform:
                    windowWidth > largeBreak
                      ? "translateY(87px)"
                      : windowWidth > 900
                      ? "translateY(67px)"
                      : windowWidth > desktopBreak
                      ? "translateY(67px)"
                      : windowWidth > tabletBreak
                      ? "translateY(56px)"
                      : "translate3D(-20px, 43px, 0px)",
                }}
                src="static/react_frontend/images/home/hero-dog.png"
                alt="border collie excited about pawpal"
              />
            </div>
          </div>
          <div
            id="hero-right"
            className="z-50 mt-12  sm:-translate-y-10  md:-translate-y-40 lg:-translate-y-20 md:mt-0 w-full h-full md:w-1/2 lg:w-1/3 md:ml-10 lg:ml-auto relative "
            style={{
              maxHeight:
                windowWidth > tabletBreak && windowWidth <= desktopBreak
                  ? "75%"
                  : windowWidth > mobileBreak && windowWidth < tabletBreak
                  ? "50vh"
                  : "auto",
              height:
                windowWidth > mobileBreak && windowWidth <= desktopBreak
                  ? "50vh"
                  : windowWidth > desktopBreak && windowWidth <= 800
                  ? "30vh"
                  : "auto",
            }}
          >
            <animated.div
              className="max-w-56 md:max-w-xs lg:max-w-sm 3xl:max-w-md absolute"
              style={heroImg1}
            >
              <img src="static/react_frontend/images/home/heroFrame1.svg" />
            </animated.div>
            <animated.div
              className="max-w-56 md:max-w-xs lg:max-w-sm 3xl:max-w-md absolute"
              style={heroImg2}
            >
              <img src="static/react_frontend/images/home/heroFrame2.svg" />
            </animated.div>
          </div>
        </div>
        {/* HERO ROW CONTENT END */}
      </div>

      {/* FEATURE SECTION START */}
      <div className="secondaryBackground w-full flex flex-col z-50 py-10 md:py-20 2xl:px-32">
        <div className="px-10 lg:px-20 ">
          <HomeFeatureRow
            windowWidth={windowWidth}
            imgSrc={"static/react_frontend/images/home/heroFrame3.svg"}
          >
            <h2 className="">Find a break nearby</h2>
            <p className="py-5">
              Let's face it, our dogs go everywhere. They're probably sat there
              staring at you right now. So let's not pretend you're not bringing
              them with you on your next visit to town.
              <br></br>
              <br></br>
              We're taking the hassle out of finding a suitable establishment
              for your next pit stop. Whether you're looking for a dog friendly
              cafe, pub, restaurant or hotel, we've got you covered. <br></br>
              <br></br>Search by location or map, and we'll show you the best
              dog friendly places that others recommend.
            </p>
          </HomeFeatureRow>
          <HomeFeatureRow
            reverse={true}
            windowWidth={windowWidth}
            imgSrc={"static/react_frontend/images/home/heroFrame4.svg"}
          >
            <h2 className="">Rate & Review</h2>
            <p className="py-5">
              Sharing is caring. Not only does leaving a review
              help others find suitable locations, it also helps businesses
              understand how they can improve their offering.
              <br></br>
              <br></br>
              First one to rate a location? Great! We'll throw a few bones your way. Collect them up and redeem for offers and rewards!
            </p>
          </HomeFeatureRow>
        </div>
      </div>
      {/* FEATURE SECTION END */}

      {/* FAQS */}
      <section
        id="faqs"
        className="w-full flex flex-col z-50 pb-10 md:pb-20 2xl:px-32"
      >
        <div className="px-10 lg:px-20 text-center">
          <h2 className="mx-auto">FAQs</h2>
          <Faq title="Is PawPal free?">
            <p>
              Yes! Your location ratings and reviews help other users and add
              value to the app ... so we wouldn't be charging you for that...
            </p>
          </Faq>
          <Faq title="Which countries do you cover?">
            <p>
              Currently just the UK, but we're ambitious ... so watch this
              space.
            </p>
          </Faq>
          <Faq title="What can I do with PawPal">
            <p>
              PawPal helps you find pubs, cafes, restaurants and more that are
              not just "dog friendly", but suitable to your dog.
              <br></br>
              <br></br>
              We encourage users to review places based on how welcoming it is
              dogs of every flavour - small, large, excitable, anxious,
              reactive.
              <br></br>
              <br></br>
              Once reviewed, other users can search for places by location, and
              filter by type. Each listing will highlight features such as water
              bowls, treats, welcoming staff and also restrictions, such as busy
              roads, outside only, near livestock etc.
            </p>
          </Faq>
          <Faq title="What else is on the roadmap?">
            <p>
              We're keen to lock in the basics first - getting a great community
              together that help review and rate locations. In future PawPal
              editions you can expect:
              <br></br>
              <ul className="py-2">
                <li>
                  <p>Personalised searches based on your dog's preferences</p>
                </li>
                <li>
                  <p>Rewards and treats for the most active dog parents</p>
                </li>
                <li>
                  <p>Localised communities & forums</p>
                </li>
                <li>
                  <p>Business profiles</p>
                </li>
              </ul>
              <a href="https://pawpal.featurebase.app/roadmap" target="_blank" className="text-teal-600">
              View our full roadmap here.</a>
            </p>
          </Faq>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default HomeScreen;
