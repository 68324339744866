import React, { useEffect, useMemo, useState } from "react";
import Nav from "../../Global/Nav";
import ListingTable from "../../Data/ListingMaster";
import axios from "axios";
import flattenObject from "../../../Utils/flattenObject";

function AdminListingManager() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {

    const filters = {};
    const url = "/fb/listings";
    const queryString = "?"
    const finalUrl = url + queryString;

    axios
      .get(finalUrl)
      .then((res) => {

        const listingsData = res.data;

        setLoading(false);
        // Set data
        setData(listingsData.map((item) => flattenObject(item)));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Added",
        accessor: "listing_created",
      },
      {
        Header: "Title",
        accessor: "title", // accessor is the "key" in the data
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Address",
        accessor: "postal_town",
      },
      {
        Header: "Map",
        accessor: "map",
      },
    ], []);

  console.log(data);
  return (
    <div>
      <Nav />
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <ListingTable columns={columns} data={data} />
      )}
    </div>
  );
}

export default AdminListingManager;
