import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link className={`nav-link ${isActive ? "active" : ""}`} to={to}>
      {children}
    </Link>
  );
}

function Nav({windowWidth, emailInputOpen, staticPage}) {

  const handleWaitlistClick = () => {
    emailInputOpen(true);
  }

  return (
    <nav className="flex py-5  w-full justify-between absolute top-0 left-0 2xl:px-32">
      <div className="flex flex-1 justify-between px-10 lg:px-20">
      <div className="flex w-22 md:w-44">
        {!staticPage ?
        <img
        className="my-auto w-full"
          src="static/react_frontend/images/logos/logo-white.svg"
          alt="pawpal logo"
        /> : <img
        className="my-auto w-full"
          src="static/react_frontend/images/logos/logo-teal.svg"
          alt="pawpal logo"
        />}
      </div>
      <div className="flex space-x-3 my-auto">
        {/* <NavLink to="/">Home</NavLink>
        <NavLink to="/admin/listings">Admin Listing Manager</NavLink>
        <NavLink to="/admin/gamify">Manage Games</NavLink> */}

        {!staticPage && (windowWidth < 500 ? (
          <a className="font-sm text-white" onClick={handleWaitlistClick}>Join Waitlist</a>
        ) : (
          <button className="transButtonOnTeal clear large px-5 py-2 rounded-lg my-5" onClick={handleWaitlistClick}>
            Join Waitlist
          </button>
        ))}
      </div>
      </div>
    </nav>
  );
}

export default Nav;
